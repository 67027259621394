<!-- 考生报考登记 -->
<template>
  <div class="register">
    <el-form ref="form" :model="form" inline class="form" id="form" label-width="100px">
      <el-form-item label="查询日期">
        <el-date-picker value-format="yyyy-MM-dd" v-model="form.dateQuery" type="daterange" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="关键字">
        <el-input v-model="form.search" placeholder="请输入姓名/报名单号/身份证/准考证号"></el-input>
      </el-form-item>
      <el-form-item label="考试大类">
        <el-select v-model="form.exam" filterable placeholder="请选择考试大类" :clearable="true">
          <el-option v-for="item in form.examOpt" :key="item.id" :label="item.exam_name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <!-- 按钮功能部分 -->
    <div class="" style="display: flex; justify-content: space-between">
      <div class="" style="margin-left: 22px; display: flex">
        <!-- 刷新 -->
        <el-button size="small" icon="el-icon-refresh" style="background-color: #2c3e50; border-radius: 3px"
          @click="refresh">
        </el-button>
        <!-- <el-upload
          class="uploader"
          action="https://artxt.szart.cn/api/public/index.php/api/common/upload"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
        >
          <img v-if="this.fullurl !== ''" :src="this.fullurl" class="avatar" />
          <el-button
            size="small"
            icon="el-icon-plus"
            style="background: #18bc9c; color: #ffffff"
          >
            导入作品
          </el-button>
        </el-upload> -->
        <!-- 批量导入照片 -->
        <el-upload ref="upload" accept=".rar,.zip"
          action="https://artxt.szart.cn/api/public/index.php/api/common/upload_photos" :limit="1" :auto-upload="true"
          :show-file-list="false" :on-success="uploadSuccess" :before-upload="beforeUpload">
          <el-button slot="trigger" size="small" class="daoru">
            批量导入
          </el-button>
        </el-upload>
        <el-button slot="trigger" size="small" class="leadingOut" @click="leadingOut">批量导出</el-button>
        <!-- <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          style="height: 30px; margin-left: 10px"
          @click="batchDelt"
        >
          删除
        </el-button> -->
      </div>

      <div class="" style="margin-right: 10px">
        <el-button size="small" style="background: #18bc9c; color: #ffffff" @click="query">
          查询
        </el-button>
        <el-button size="small" style="background: #fafafa; color: #666666" @click="Reset">
          重置
        </el-button>
      </div>
    </div>

    <!-- <div class="chosebox">
      <el-button
        type="primary"
        size="mini"
        style="height: 30px"
        @click="choseAll(choseTitle)"
      >
        {{ choseTitle }}
      </el-button>
    </div> -->

    <!-- 内容作品集合 -->
    <div class="pic-box" :style="{
      'grid-template-rows':
        'repeat(' + Math.ceil(artworkList.length / 4) + ', 210px)',
      height: Math.ceil(artworkList.length / 8) * 230 + 'px',
    }">
      <div class="compic" v-for="(img, idex) in artworkList" :key="idex">
        <!-- fit="cover" -->
        <!-- :preview-src-list="artworkList" -->
        <el-image class="pic" :src="'https://artxt.szart.cn/api/public' + img.photo_url" :preview-src-list="srcList"
          :initial-index="idex">
        </el-image>
        <div class="comtitle">{{ img.course + img.exam_level }}</div>
        <!-- <div class="comtitle">{{ img.photo_name }}</div> -->
        <!-- 选中按钮 -->
        <div class="radio" @click="choseItem(img)">
          <div v-if="img.checked" class="radio-selected"></div>
        </div>
      </div>
    </div>

    <!--分页 -->
    <el-pagination background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange"
      @current-change="handleCurrentChange" :current-page="currentPage"
      :page-sizes="[20, 100, 500, 1000, 2000, 5000, 10000, 50000]" :page-size="10" :total="total">
    </el-pagination>

    <!-- 弹出提示 -->
    <el-dialog title="提示" :visible.sync="dialog" width="40%" :before-close="handleClose">
      <span>正在导入相片中，请稍等。。。</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button style="background: #409eff; color: #fff" @click="dialog = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fullurl: "",
      dialog: false, //用于判断弹窗
      choseTitle: "全选",
      startdate: "", //开始日期
      enddate: "", //结束日期
      form: {
        dateQuery: [], //查询日期
        search: "", //关键字
        exam: "", //考试大类
        examOpt: [], //考试大类选项
      },
      currentPage: 1, //页数
      currentLimit: 20, //条数
      total: 0, //总数
      ids: [],
      id: "",
      arr: [],
      picids: [],
      artworkList: [],
    };
  },

  computed: {
    srcList() {
      return this.artworkList.map((img) => {
        console.log(img.photo_url);
        return "https://artxt.szart.cn/api/public" + img.photo_url;
      });
    },
  },

  created() {
    this.getList();
    // 考试大类
    this.$request({
      url: "/api/ExamCategory/list",
      method: "POST",
      data: {
        page: 1,
        limit: 1000,
      },
    }).then((res) => {
      // console.log(res,"考试大类")
      if (res.code == 1) {
        this.form.examOpt = res.data.list;
      }
    });
  },

  activated() {
    this.getList();
    // console.log("页面缓存")
  },

  methods: {
    getList() {
      let locaid = JSON.parse(localStorage.getItem("get_info"));
      this.$request({
        url: "/api/examenroll/list",
        method: "POST",
        data: {
          page: this.currentPage,
          limit: this.currentLimit,
          dateQuery: this.form.dateQuery, //查询时间
          search: this.form.search, //关键字
          apply_category: this.form.exam,
          sort: this.arr,
          upload_url: 1,
          org_id: locaid.id,
        },
      }).then((res) => {
        this.total = res.data.count;
        this.artworkList = res.data.list;
      });
    },

    // 全选
    choseAll(title) {
      if (title == "全选") {
        this.choseTitle = "全不选";
        this.picList.forEach((pic) => {
          pic.checked = true;
        });
      } else {
        this.choseTitle = "全选";
        this.picList.forEach((pic) => {
          pic.checked = false;
        });
      }
    },

    choseItem(item) {
      item.checked = !item.checked;
    },

    // 批量导入照片
    beforeUpload(file) {
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (extension !== "zip") {
        this.$message({
          message: "上传文件只能是zip格式!",
          type: "warning",
        });
        return false;
      }
      this.dialog = true;
    },

    uploadSuccess(res, file, fileList) {
      this.dialog = false;
      if (res.code == 1) {
        this.$message({
          message: "导入成功!",
          type: "success",
        });
      } else {
        this.$alert(res.msg, "导入失败", {
          confirmButtonText: "确定",
        });
      }
      this.$refs.upload.clearFiles();
    },

    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => { });
    },

    // 上传图片
    handleAvatarSuccess(res, file) {
      console.log(res, file);
      this.imageUrl = res.data;
      this.fullurl = this.imageUrl.fullurl;
      this.url = this.imageUrl.url;
    },

    beforeAvatarUpload(file) {
      console.log(file.type);
      const isJPG = file.type === "image/jpg";
      const isJPEG = file.type === "image/jpeg";
      const isLt1M = file.size / 1024 / 1024 < 3;
      const isLt3k = file.size / 1024 < 300;
      let flag = [isJPG, isJPEG].includes(true);
      if (!flag) {
        this.$message.error("上传作品图片只能是 JPG/JPEG 格式!");
      }
      if (isLt3k) {
        this.$message.error("上传作品图片大小不能小于 300k!");
      }
      if (!isLt1M) {
        this.$message.error("上传作品图片大小不能超过 3MB!");
      }
      return flag && isLt1M;
    },

    //查询
    query() {
      this.currentPage = 1;
      this.getList();
    },

    //重置
    Reset() {
      this.form.dateQuery = []; //查询时间
      this.form.search = ""; //关键字
      this.form.exam = "";
      this.getList();
    },

    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.currentLimit = val;
      this.getList();
    },

    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getList();
    },

    // 刷新
    refresh() {
      this.$router.push({
        path: "/newpage", //空页面路由
        query: {
          path: this.$route.path,
        },
      });
    },

    batchDelt() {
      //批量删除
      if (this.picids.length) {
        this.$confirm("是否删除所选中的作品?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$request({
            url: "/api/examenroll/del",
            method: "POST",
            data: {
              id: this.ids,
            },
          }).then((res) => {
            if (res.code == 1) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              this.getList();
            }
          });
        });
      } else {
        this.$message("请先选择要删除的作品");
      }
    },

    // 姓名排序
    fn2(a, b) {
      return a.name.localeCompare(b.name);
    },

    //修改考试大类
    modify() {
      //
    },

    //批量导出照片
    leadingOut() {
      if (this.artworkList.length) {
        this.$request({
          url: "/api/examenroll/upload_url_down",
          method: "POST",
          data: {
            page: this.currentPage,
            limit: this.currentLimit,
            dateQuery: this.form.dateQuery, //查询时间
            search: this.form.search, //关键字
            apply_category: this.form.exam,
            sort: this.arr,
            upload_url: 1,
          },
        }).then((res) => {
          console.log(res, "批量导出照片");
          let a = document.createElement("a");
          a.href = res.data;
          a.click();
        });
      } else {
        this.$message({
          message: "没有可导出的作品！",
          type: "error",
        });
      }
    },
  },
};
</script>

<style scoped="scoped">
.register {
  width: 100%;
  height: 100%;
}

/* 查询部分 */
.form {
  padding-top: 34px;
  display: flex;
  flex-wrap: wrap;
}

.form ::v-deep .el-input__inner {
  width: 276px;
  height: 37px;
}

.form ::v-deep .el-form-item__label {
  color: #000000;
}

.el-dialog__body .el-select {
  width: 300px;
}

::v-deep .footer {
  margin: 15px 0px !important;
  text-align: center !important;
  /* height: 50px !important; */
}

/* 按钮 */
::v-deep .el-icon-refresh {
  font-weight: bold !important;
  color: #ffffff;
}

::v-deep .el-icon-plus {
  font-weight: bold !important;
}

.uploader {
  margin-left: 10px;
}

/* 批量导入按钮 */
.daoru {
  background: url(../../assets/import.png) 8px 7px no-repeat #ff7300;
  color: #ffffff;
  height: 30px;
  width: 95px;
  padding-left: 25px;
  border: none;
  vertical-align: middle;
  margin-left: 10px;
  cursor: pointer;
}

/* 导出按钮 */
.leadingOut {
  width: 95px;
  height: 30px;
  vertical-align: middle;
  background: url(../../assets/export.png) 8px 7px no-repeat #ff7300;
  color: #ffffff;
  border-radius: 3px;
  border: none;
  margin-left: 10px;
  cursor: pointer;
}

.el-form-item__content {
  text-align: center;
}

/* 全选 */
.chosebox {
  margin: 15px 0 0 20px;
}

/* 内容作品集合 */
.pic-box {
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 20px 20px;
}

.pic-box .compic {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pic-box .compic .pic {
  width: auto;
  height: 260px;
}

.pic-box .compic .comtitle {
  margin-top: 10px;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
}

.pic-box .compic .radio {
  position: absolute;
  top: 5px;
  right: 3px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
}

.pic-box .compic .radio .radio-selected {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #409eff;
}

/* 分页 */
.el-pagination {
  margin: 20px;
  margin-top: 0px;
  padding-bottom: 30px;
}

::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #2c3e50 !important;
}
</style>
